import React, { ReactNode, ReactElement } from 'react';
import { InputAdornment, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

import { useMenuController } from '~/common/components';

export interface InputAdornmentMenuProps<T extends string> {
  value: T;
  options: Array<InputAdornmentOption<T>>;
  onChange: (value: T) => void;
  labelComponent?: (props: InputAdornmentOption<T>) => ReactElement;
  adornmentStyle?: object;
}

export interface InputAdornmentOption<T extends string> {
  value: T;
  label: ReactNode;
} 

export function InputAdornmentMenu<T extends string>(props: InputAdornmentMenuProps<T>) {
  const { labelComponent: Label, adornmentStyle } = props;
  
  const {
    isOpen,
    anchorEl,
    openMenu,
    closeMenu,
  } = useMenuController();

  const changeHandler = (value: T) => () => {
    props.onChange(value);
    closeMenu();
  };
  
  const currentOption = props.options.find(opt => opt.value === props.value);
  
  return (
    <>
      <InputAdornment
        position="end"
        sx={{
          cursor: 'pointer',
          border: 'solid 1px #ccc',
          borderRadius: '3px',
          paddingLeft: '8px',

          borderColor: isOpen ? '#888' : '#ccc',
          '&:hover': {
            borderColor: '#888'
          },
          ...adornmentStyle
        }}
        onClick={openMenu}
      >
        {currentOption && (
          <span>
            {Label 
              ? <Label value={currentOption.value} label={currentOption.label} />
              : currentOption.label
            }
          </span>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 20 }} />
      </InputAdornment>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
      >
        {props.options.map(option => (
          <MenuItem
            key={option.value}
            onClick={changeHandler(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
