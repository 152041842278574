import React from 'react';

import { Subscript } from '../subscript';

import { InputAdornmentMenu } from './input-adornment-menu';

export interface ElectricityCurrentModeAdornmentMenuProps {
  value: ElectrolyzerCapacityCurrentMode;
  onChange: (value: ElectrolyzerCapacityCurrentMode) => void;
}

export enum ElectrolyzerCapacityCurrentMode {
  ac = 'ac',
  dc = 'dc',
}

export function ElectricityCurrentModeAdornmentMenu(props: ElectricityCurrentModeAdornmentMenuProps) {
  return (
    <InputAdornmentMenu
      {...props}
      labelComponent={({ label }) => {
        return <>MW [{label}]</>;
      }}
      options={[
        {
          value: ElectrolyzerCapacityCurrentMode.ac,
          label: 'AC'
        },
        {
          value: ElectrolyzerCapacityCurrentMode.dc,
          label: 'DC'
        }
      ]}
    />
  )
}

