import React, { useState, useCallback } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { useAsync } from 'react-async';

import { useSharedStyles } from './shared.styles';
import { viewkey } from '~/modules/lcohplus/views/scenario-dashboard/new-features-dialog';

export interface LoginProps {
  submit: (email: string, password: string) => Promise<unknown>;
  onSubmitSuccess?: () => void;
}

export function LoginForm({ submit, onSubmitSuccess }: LoginProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const deferFn = useCallback(() => submit(email, password), [email, password, submit]);
  const handleResolve = useCallback(() => {
    setIsSuccessful(true);
    onSubmitSuccess?.();
  }, [onSubmitSuccess]);
  const { run: login, isPending, isRejected } = useAsync({
    deferFn,
    onResolve: handleResolve
  });

  const canSubmit = email && password && !isPending && !isSuccessful;

  const classes = useSharedStyles();

  const handleLogin = () => {
    // reset viewkey to show "returning user" dialog on lcoh+
    localStorage.setItem(viewkey, 'false');
    login();
  }

  return (
    <form onSubmit={login} className={classes.root}>
      <TextField
        autoFocus
        label="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />

      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <Button
        type="submit"
        onClick={handleLogin}
        disabled={!canSubmit}
        color="primary"
      >Login</Button>


      <Typography color="secondary">
        {isRejected && <>Unable to login</>}
        &nbsp;
      </Typography>
    </form>
  );
}
