import React, { ComponentType, useState, FocusEvent, ReactNode } from 'react';
import InputNumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { TextField, TextFieldProps } from '@mui/material';

const percentageTransformer: ModelNumberFieldTransformer = [(v) => v * 100, (v) => v / 100];
const millionsTransformer: ModelNumberFieldTransformer = [(v) => v / 1_000_000, (v) => v * 1_000_000];

export const modelNumberFieldTransformers = {
  percentage: percentageTransformer,
  millions: millionsTransformer,
}

export type ModelNumberFieldTransformer = [(value: number) => number, (value: number) => number];

export type NumberFieldProps =
  Omit<TextFieldProps, 'onChange'|'value'|'onKeyPress'|'onBlur'> & {
  prefix?: string;
  suffix?: ReactNode;
  tooltip?: ReactNode;
  value?: number;
  onChange?: (value: number | undefined) => void;
  onBlur?: NumberFieldBlurHandler;
  transform?: [(value: number) => number, (value: number) => number];
  thousandSeparator?: boolean | string;
  decimals?: number; // convenience prop
  allowNegative?: boolean;
};

export function NumberField({ onChange, onBlur, ...props }: NumberFieldProps) {
  const { thousandSeparator, ...rest } = props;

  let value;
  if (props.value === undefined) {
    value = '';
  } else {
    value = props.transform ? props.transform[0](props.value) : props.value;
  }

  const handleChange = (val: number | undefined) => {
    if (props.transform && val !== undefined) {
      onChange?.(props.transform[1](val));
    } else {
      onChange?.(val);
    }
  }

  return (
    <TextField
      {...rest}
      InputProps={{
        ...props.InputProps,
        inputComponent: FormattedNumberInput as ComponentType,
      }}
      inputProps={{
        ...props.inputProps,
        allowNegative: props.allowNegative,
        thousandSeparator: props.thousandSeparator,
        decimalScale: props.decimals ?? 2,
        onValueChange: handleChange,
        onValueBlur: onBlur,
      }}
      value={value}
    />
  );
}


interface FormattedNumberInputProps extends Omit<NumberFormatProps, 'onValueChange' | 'onKeyPress'> {
  inputRef: (el: HTMLInputElement) => void;
  onValueChange?: (value: number | undefined) => void;
  onValueBlur?: NumberFieldBlurHandler;
}

export type NumberFieldBlurHandler = (e: FocusEvent, ev: { floatValue: number | undefined }) => void;
export type NumberFieldFocusHandler = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

function FormattedNumberInput(props: FormattedNumberInputProps) {
  const { onValueChange, inputRef, onValueBlur, thousandSeparator = true, allowNegative = false, ...rest } = props;

  const [formattedValue, setFormattedValue] = useState<string>('');
  const [floatValue, setFloatValue] = useState<number|undefined>();

  const handleValueChange = (values: NumberFormatValues) => {
    setFormattedValue(values.formattedValue);
    setFloatValue(values.floatValue);
    onValueChange?.(values.floatValue);
  };

  const handleBlur = (ev: FocusEvent<HTMLInputElement>) => {
    onValueBlur?.(ev, { floatValue: floatValue });
    props.onBlur?.(ev); // continue propagation for MUI TextField so that styles get applied
  };

  return (
    <InputNumberFormat
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      value={formattedValue}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      {...rest}
      onBlur={handleBlur}
      // decimalScale={props.decimalScale || 2}
    />
  );
}
