import { makeStyles} from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { eh2green } from '~/common/styles/constants';

export interface FormMessageStylesProps {
  kind?: FormMessageKind;
}

export type FormMessageKind = 'error' | 'success';

export const useFormMessageStyles = makeStyles<Theme, FormMessageStylesProps>(theme => ({
  root: {
    fontSize: 12,
    color: ({ kind }) => {
      if (kind === 'success') {
        return eh2green;
      }

      if (kind === 'error') {
        return theme.palette.secondary['main'];
      }
    }
  }
}));
