import React, { useState, useEffect, KeyboardEvent } from "react";
import { InputAdornment, TooltipProps } from "@mui/material";
import { InsightTooltip, InsightTooltipProps } from "~/common/components";
import clsx from "clsx";

import {
  NumberField as BaseNumberField,
  NumberFieldProps as BaseNumberFieldProps,
  NumberFieldBlurHandler,
  NumberFieldFocusHandler,
  modelNumberFieldTransformers,
} from "../number-field";

import { InfoTooltip } from "../info-tooltip";
import { useModelNumberFieldStyles } from "./model-number-field.styles";

export { modelNumberFieldTransformers };

export interface Insight {
  text: string;
  visible: boolean;
  hidden?: boolean;
}

export interface LazyNumberFieldProps extends BaseNumberFieldProps {
  tooltipProps?: Partial<TooltipProps>;
  onCommitChange?: (value: number | undefined) => void;
  shaded?: boolean;
  disablePrefix?: boolean;
  allowNegative?: boolean;
  className?: string;
  showHelperTextWhenDisabled?: boolean;
  insight?: Insight;
  insightProps?: Partial<InsightTooltipProps>;
  disabled?: boolean;
}

export function ModelNumberField(props: LazyNumberFieldProps) {
  const { shaded, tooltipProps, onCommitChange, error, ...rest } = props;

  const [committedValue, setCommittedValue] = useState(props.value);
  const [currentValue, setCurrentValue] = useState<number | undefined>(
    props.value
  );
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setCommittedValue(props.value);
    setCurrentValue(props.value);
  }, [props.value]);

  const handleChange = (value: number | undefined) => {
    props.onChange?.(value);
    setCurrentValue(value);
  };

  const commitChange = () => {
    if (currentValue !== committedValue || currentValue === undefined) {
      setCommittedValue(currentValue);
      onCommitChange?.(currentValue);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      commitChange();
    }
  };

  const handleBlur: NumberFieldBlurHandler = (e, ev) => {
    commitChange();
    setFocused(false);
    props.onBlur?.(e, ev);
  };

  const handleFocus: NumberFieldFocusHandler = (e) => {
    setFocused(true);
    props.onFocus?.(e);
  };

  const classes = useModelNumberFieldStyles();

  const InputProps = {
    className: clsx({ [classes.derived]: props.shaded }),
    // classes: {
    //   inputAdornedStart: classes.muiInputAdornedStart,
    //   inputAdornedEnd: classes.muiInputAdornedEnd,
    // },
    startAdornment:
      props.disablePrefix && props.prefix ? undefined : (
        <InputAdornment
          position="start"
          className={classes.inputStartAdornment}
        >
          {props.prefix}
        </InputAdornment>
      ),
    endAdornment: (
      <InputAdornment position="end">
        {props.suffix && (
          <span className={classes.inputSuffix}>{props.suffix}</span>
        )}

        {props.tooltip && (
          <InfoTooltip
            {...tooltipProps}
            title={props.tooltip}
            classes={{ infoIcon: classes.fieldInfoIcon }}
          />
        )}
        {props.insight?.visible && (
          <InsightTooltip
            title={props.insight.text}
            startOpen={true}
            inputFocused={focused}
            {...props.insightProps}
            style={{
              position: "absolute",
              top: "calc(50% - 9px)",
              left: "6ch",
              ...props.insightProps?.style
            }}
          />
        )}
      </InputAdornment>
    ),
  };

  return (
    <BaseNumberField
      thousandSeparator
      {...rest}
      error={props.disabled ? undefined : props.error}
      InputProps={InputProps}
      helperText={
        props.showHelperTextWhenDisabled
          ? props.helperText
          : props.disabled
          ? " "
          : props.helperText || " "
      }
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      value={currentValue}
      disabled={shaded || props.disabled}
      className={clsx(classes.muiTextField, props.className)}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
    />
  );
}
