import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface DividerStylesProps {
  color?: string
}

export const useDividerStyles = makeStyles<Theme, DividerStylesProps>(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },

  lineContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  line: {
    borderTop: ({ color }) => `solid 1px ${color || '#ccc'}`,
  },

  left: {
    background: theme.palette.background.default,
    position: 'absolute',
    paddingRight: theme.spacing(1),
  },

  right: {
    background: theme.palette.background.default,
    position: 'absolute',
    right: 0,
    paddingLeft: theme.spacing(1),
  }
}));
