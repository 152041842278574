import React, { useState } from 'react';
import { Button, TextField, Typography, Popover } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

import { checkValidEmail } from '~/common/util';
import { getPendingButtonProps } from '~/common/components/button';

import { useSharedStyles } from './shared.styles';

export interface RegisterFormProps {
  submit: (data: RegisterFormSubmitData) => void;
  checkEmailAddress: (emailAddress: string) => Promise<boolean>;
  isSubmitting: boolean;
  error?: string;
}

export interface RegisterFormSubmitData {
  email: string;
  password: string;
  institution?: string;
}

export function RegisterForm(props: RegisterFormProps) {
  const [email, setEmail] = useState('');
  const [institution, setInstitution] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const payload = {
    email: email.trim(),
    password: password.trim(),
    institution: institution.trim(),
  };

  const canSubmit = (
    !props.isSubmitting &&
    checkValidEmail(payload.email) &&
    !!payload.institution &&
    payload.password &&
    payload.password === confirmPassword
  );

  const submit = () => {
    if (canSubmit) {
      props.submit({
        email,
        password,
        institution
      });
    }
  };

  const [emailAddressChecked, setEmailAddressChecked] = useState(false);
  const [emailAddressNoticeAnchorElement, setEmailAddressNoticeAnchorElement] = useState<HTMLDivElement | null>(null);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState<boolean>(false);
  const handleBlurEmailAddress = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (emailAddressChecked || !event.target.value) {
      return;
    }
    setEmailAddressNoticeAnchorElement(event.currentTarget);
    const isValid = await props.checkEmailAddress(email);
    setEmailAddressChecked(true);
    setIsEmailAddressValid(isValid);
  };

  const sharedClasses = useSharedStyles();


  return (
    <form onSubmit={submit} className={sharedClasses.root}>
      <TextField
        autoFocus
        label="Institution / company"
        value={institution}
        onChange={e => setInstitution(e.target.value)}
      />

      <TextField
        label="Email (company email preferred)"
        value={email}
        onChange={e => setEmail(e.target.value)}
        onBlur={handleBlurEmailAddress}
      />

      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <TextField
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
      />

      <Button
        type="submit"
        color="primary"
        onClick={submit}
        {...getPendingButtonProps({
          pending: props.isSubmitting,
          invalid: !canSubmit
        })}
      >Register</Button>

      {props.error && <Typography color="secondary">{props.error}</Typography>}

      <Popover
        open={
          emailAddressChecked &&
          !isEmailAddressValid &&
          !!emailAddressNoticeAnchorElement
        }
        onClose={() => setEmailAddressNoticeAnchorElement(null)}
        anchorEl={emailAddressNoticeAnchorElement}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className={sharedClasses.popoverContent}>
          <ErrorOutlineIcon />
          <Typography className={sharedClasses.popoverText}>
            <span style={{ fontWeight: 'bold' }}>Company email</span> is preferred to get the most out of EH2 Analytics tools.
          </Typography>
        </div>
      </Popover>
    </form>
  );
}
