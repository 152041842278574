import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import clsx from 'clsx';

import { useInfoTooltipStyles } from './info-tooltip.styles';

export type InfoTooltipProps = 
  Omit<TooltipProps, 'children'|'classes'> & {
  classes?: InfoTooltipClasses;
}

export type InfoTooltipClasses = TooltipProps['classes'] & {
  infoIcon?: any; // todo
};

export function InfoTooltip(props: InfoTooltipProps) {
  const classes = useInfoTooltipStyles();
  return (
    <Tooltip
      {...props}
      style={{
        cursor: 'pointer',
        borderRadius: '50%',
        ...props.style,
      }}
    >
      <InfoIcon className={clsx(classes.icon, props.classes?.infoIcon)}/>
    </Tooltip>
  )
}
