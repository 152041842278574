import { makeStyles } from '@mui/styles';
import type { Theme } from '~/common/styles/theme';

export const useFileUploadStyles = makeStyles<Theme>((theme) => {
  return {
    fileDropzone: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),

      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },

      border: 'dashed 4px #ccc',
      borderRadius: theme.shape.borderRadius,
    },
    fileDropzoneActive: {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      border: `dashed 4px ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    fileEmpty: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
      color: 'inherit',
    },
    filePreview: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
      padding: theme.spacing(3),
      border: 'dashed 4px #ccc',
      borderRadius: theme.shape.borderRadius,
    },
    fileClearBtn: {
      '&.MuiButtonBase-root': {
        position: 'absolute',
        top: 12,
        right: 12,
        backgroundColor: 'rgba(0,0,0,.4)',
        color: '#fff',

        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.6)',
        },
      },
    },
    fileContainer: {
      position: 'relative',
      margin: '0 auto',
      width: '100%',
      overflow: 'hidden',
    },
  };
});
