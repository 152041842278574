import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { FormControl, InputLabel, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';

import { InfoTooltip, InfoTooltipProps } from '../info-tooltip';

import { useSelectStyles } from './select.styles';

export type SelectProps<T> = Omit<MuiSelectProps, 'onChange'> & {
  onChange: (value: T) => void;
  label?: string;
  tooltip?: ReactNode;
  tooltipProps?: Partial<InfoTooltipProps>;
  shaded?: boolean;
};

export function Select<T>({ label, fullWidth, children, onChange, native = true, shaded, ...props }: SelectProps<T>) {
  const inputLabel = useRef<HTMLLabelElement>(null);

  const handleChange: MuiSelectProps['onChange'] = (e) => {
    onChange(e.target.value as T);
  }

  const classes = useSelectStyles();

  return (
    <FormControl fullWidth={fullWidth} style={{ paddingBottom: 9.5 }}>
      {label && (
        <InputLabel disabled={props.disabled} shrink={true} ref={inputLabel}>
          {label}
        </InputLabel>
      )}

      {props.tooltip && (
        <InfoTooltip
          title={props.tooltip}
          className={classes.tooltip}
          {...props.tooltipProps}
        />
      )}
      <MuiSelect
        {...props}
        label={label}
        onChange={handleChange}
        native={native}
        disabled={shaded || props.disabled}
        style={{
          background: shaded ? '#eee' : undefined,
          fontSize: 16,
          ...props.style,
        }}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
}
