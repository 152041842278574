import { makeStyles } from '@mui/styles';
import type { Theme } from '~/common/styles/theme';

export const useSelectStyles = makeStyles<Theme>(theme => ({
  tooltip: {
    position: 'absolute',
    right: -7,
    top: -8,
    zIndex: 1,
    background: theme.palette.background.default
  }
}));
