import { makeStyles } from '@mui/styles';
import type { Theme } from '~/common/styles/theme';

export const useChassisV2Styles = makeStyles<Theme>(theme => ({
  root: {
    height: '100vh'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  toolbarDropdown: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: 14,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));
