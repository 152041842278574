export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  AUD = 'AUD',
  CAD = 'CAD',
  GBP = 'GBP'
}

export const DEFAULT_CURRENCY = Currency.USD;

export function getCurrencySymbol(currency?: Currency) {
  switch (currency) {
    case Currency.EUR: return '€';
    case Currency.AUD: return 'A$';
    case Currency.CAD: return 'C$';
    case Currency.GBP: return '£';
    default: return '$';
  }
}

export const defaultUsdExchangeRates = {
  [Currency.USD]: 1,
  [Currency.EUR]: 0.98,
  [Currency.AUD]: 1.51,
  [Currency.CAD]: 1.35, 
  [Currency.GBP]: 0.80,
};


const noop = (n: number|undefined) => n ?? 0;
export const usdToUsd = noop;

// usd to/from eur
export const usdToEur = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd * defaultUsdExchangeRates[Currency.EUR];
};
export const eurToUsd = (eur: number|undefined) => {
  if (eur === undefined) {
    return 0;
  }
  return eur / defaultUsdExchangeRates[Currency.EUR];
};

// usd to/from aud
export const usdToAud = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd * defaultUsdExchangeRates[Currency.AUD]
};
export const audToUsd = (aud: number|undefined) => {
  if (aud === undefined) {
    return 0;
  }
  return aud / defaultUsdExchangeRates[Currency.AUD]
};

// usd to/from cad
export const usdToCad = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd * defaultUsdExchangeRates[Currency.CAD];
};
export const cadToUsd = (cad: number|undefined) => {
  if (cad === undefined) {
    return 0;
  }
  return cad / defaultUsdExchangeRates[Currency.CAD];
};

// gbp to/from usd
export const usdToGbp = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd * defaultUsdExchangeRates[Currency.GBP];
};
export const gbpToUsd = (gbp: number|undefined) => {
  if (gbp === undefined) {
    return 0;
  }
  return gbp / defaultUsdExchangeRates[Currency.GBP];
};

export type CurrencyTransformer = [(n?: number) => number, (n?: number) => number];
export function createCurrencyTransform(currency?: Currency, customExchangeRate?: number): CurrencyTransformer {
  if (customExchangeRate) {
    const usdToX = (usd: number|undefined) => usd ? usd / customExchangeRate : 0;
    const xToUsd = (x: number|undefined) => x ? x * customExchangeRate : 0;
    return [usdToX, xToUsd]
  }
  
  if (currency === Currency.AUD) {
    return [usdToAud, audToUsd];
  }
  if (currency === Currency.EUR) {
    return [usdToEur, eurToUsd];
  }
  if (currency === Currency.CAD) {
    return [usdToCad, cadToUsd];
  }
  if (currency === Currency.GBP) {
    return [usdToGbp, gbpToUsd];
  }
  return [usdToUsd, usdToUsd];
}
