import React, { ReactNode } from 'react';
import { AppBar, Link, Toolbar, Menu, MenuItem, Typography, IconButton, Tooltip } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';

import { Sidebar, SidebarProps } from '../sidebar';
import { useMenuController } from '../menu';
import { TruncatedEmail } from '../truncated-email';

import { useChassisV2Styles } from './chassis-v2.styles';

export interface ChassisV2Props {
  children: ReactNode;
  sidebarProps: SidebarProps;
  topMenuProps: ChassisV2TopMenuProps;
  title: ReactNode;
  showTos: boolean;
  showApi?: boolean;
  tos: ReactNode;
  isReadonly?: boolean;
  isDlcohLite?: boolean;
  showLcohplusTutorials?: boolean;
}

export interface ChassisV2TopMenuProps {
  hasApiAccess?: boolean;
  aboutPath?: string;
  privacyPolicyPath: string;
  changeLogPath?: string;
  changePassword: () => void;
  logout: () => void;
  emailAddress?: string;
}

export function ChassisV2(props: ChassisV2Props) {
  const classes = useChassisV2Styles();
  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="default">
        <Toolbar disableGutters variant="dense" className={classes.toolbar}>
          <div className={classes.left}>
            {!props.isReadonly
              ? <Sidebar {...props.sidebarProps} />
              : <div style={{ width: 24 }} />}
            {props.title}
          </div>
          <div className={classes.right}>

            {props.showLcohplusTutorials && (
              <Link href="/lcoh+/tutorials" sx={{ fontSize: '14px' }}>Tutorials</Link>
            )}
            {!props.isReadonly && props.showApi && (
              <>
                {props.topMenuProps.hasApiAccess && (
                  <Link href="/lcoh+/api" sx={{ fontSize: '14px' }}>API</Link>
                )}

                {!props.topMenuProps.hasApiAccess && (
                  <Tooltip title="For more information, please reach out to analytics@eh2.com">
                    <span style={{ cursor: 'not-allowed', color: '#aaa', display: 'flex', alignItems: 'center', gap: 4 }}>
                      <span>API</span>
                      <span style={{ fontSize: 20 }}>🔒</span>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {!props.isReadonly && (
              <HelpMenu {...props.topMenuProps} />
            )}
            <Eh2Menu />
            <Link className={classes.toolbarDropdown} href="mailto: analytics@eh2.com">Feedback?</Link>

            {!props.isReadonly && (
              <ProfileMenu
                changePassword={props.topMenuProps.changePassword}
                logout={props.topMenuProps.logout}
                emailAddress={props.topMenuProps.emailAddress}
              />
            )}
          </div>
        </Toolbar>
      </AppBar>

      <main>
        {props.showTos
          ? props.tos
          : props.children
        }
      </main>
    </div>
  );
}

function HelpMenu(props: ChassisV2TopMenuProps) {
  const menuController = useMenuController();
  const classes = useChassisV2Styles();
  return (
    <>
      <Link
        component="button"
        className={classes.toolbarDropdown}
        onClick={menuController.openMenu}
      >
        About <ArrowDropDownIcon/>
      </Link>
      <Menu open={menuController.isOpen} anchorEl={menuController.anchorEl} onClose={menuController.closeMenu}>
        {props.aboutPath && (
          <MenuItem dense component="a" href={props.aboutPath} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography variant="body2">About</Typography>
          </MenuItem>
        )}
        {props.changeLogPath && (
          <MenuItem dense component="a" href={props.changeLogPath} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography variant="body2">Change Log</Typography>
          </MenuItem>
        )}

        <MenuItem dense component="a" href={props.privacyPolicyPath} target="_blank" style={{ textDecoration: 'none' }}>
          <Typography variant="body2">Privacy Policy</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

function Eh2Menu() {
  const menuController = useMenuController();
  const classes = useChassisV2Styles();
  return (
    <>
      <Link
        component="button"
        className={classes.toolbarDropdown}
        onClick={menuController.openMenu}
      >
        EH2.com <ArrowDropDownIcon/>
      </Link>
      <Menu open={menuController.isOpen} anchorEl={menuController.anchorEl} onClose={menuController.closeMenu}>
        <MenuItem dense component="a" href="https://eh2.com">
          <Typography variant="body2">Home Page</Typography>
        </MenuItem>
        <MenuItem dense component="a" href="https://eh2.com/toolkit">
          <Typography variant="body2">Toolkit</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

interface ProfileMenuProps {
  emailAddress?: string;
  changePassword: () => void;
  logout: () => void;
}

function ProfileMenu(props: ProfileMenuProps) {
  const menuController = useMenuController();

  return (
    <>
      <IconButton onClick={menuController.openMenu}>
        <AccountCircleIcon />
      </IconButton>

      <Menu
        open={menuController.isOpen}
        anchorEl={menuController.anchorEl}
        onClose={menuController.closeMenu}
      >
        {props.emailAddress && (
          <MenuItem disabled>
            <TruncatedEmail
              email={props.emailAddress}
              style={{ fontSize: 14, fontWeight: 'bold' }}
            />
          </MenuItem>
        )}
        <MenuItem dense onClick={props.changePassword}>
          Change Password
        </MenuItem>
        <MenuItem dense onClick={props.logout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
