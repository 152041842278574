import React from 'react';
import { CircularProgress } from '@mui/material';

export function PagePending() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%'
    }}>
      <div>
        <CircularProgress />
      </div>
    </div>
  )
}
