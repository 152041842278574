import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { eh2green } from '~/common/styles/constants';

const drawerWidth = 320;

export const useSidebarStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    menuSubheader: {
      color: eh2green,
      fontSize: 20,
      fontWeight: 'bold',
      borderLeft: `solid 4px transparent`,
    },
    listItem: {
      borderLeft: `solid 4px transparent`,
      '&.MuiListItem-root': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    listItemIndented: {
      paddingLeft: 36,
    },
    listItemActive: {
      borderLeft: `solid 4px ${eh2green}`,
    },
    menuButton: {
      margin: 10,
      zIndex: 1,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'space-between',
    },
    lists: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: 24,
    },
    menuItemIcon: {
      marginLeft: 8,
    },
    linkWrapper: {
      cursor: 'pointer',
      transition: 'background-color 300ms',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  })
);
