import { makeStyles } from '@mui/styles';

export const useAuthFormLayoutStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 56px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
