import { makeStyles } from '@mui/styles';
import type { Theme } from '~/common/styles/theme';

export const useChassisStyles = makeStyles<Theme>(theme => ({
  header: {
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1)
  },
  logoutBtn: {
    background: theme.palette.background.default,
    marginLeft: theme.spacing(2)
  },
  contactBtn: {
    background: theme.palette.background.default,
  }
}));
