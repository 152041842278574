import React, { useState, useCallback } from 'react';
import { Button, Link, TextField, Typography } from '@mui/material';
import { useAsync } from 'react-async';

import { useSharedStyles } from './shared.styles';

export interface InitForgotPasswordResetFormProps {
  submit?: (email: string) => Promise<unknown>;
}

export function InitForgotPasswordResetForm(props: InitForgotPasswordResetFormProps) {
  const [email, setEmail] = useState('');

  const deferFn = useCallback(async () => {
    if (email) {
      props.submit?.(email);
    }
  }, [email, props]);
  const { run: submit, isPending, isRejected, isFulfilled } = useAsync({ deferFn });

  const canSubmit = !isPending && !isFulfilled && !!email;

  const classes = useSharedStyles();

  return (
    <div className={classes.root}>
      <TextField
        autoFocus
        disabled={isPending || isFulfilled}
        label="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />

      <Button
        onClick={submit}
        disabled={!canSubmit}
        color="primary"
      >Send Reset-Password Link</Button>

      <Typography 
        color={isRejected ? 'secondary' : 'initial'} 
        style={{ width: 300, marginBottom: 8, fontSize: 14, fontWeight: 'bold' }}
      >
        {isFulfilled && (
          <>
            <p>
              Please check your inbox and spam folder for an email containing a link to reset your password.
            </p>
            <p>
              If you do not receive an email, please contact us at <Link href="mailto: info@eh2.com" style={{ color: 'blue' }}>info@eh2.com</Link>, and we will help resolve your issue promptly.
            </p>
          </>
        )}
        {isRejected && (
          <>
            <p>
              An error occurred
            </p>
            <p>
              Please contact us at <Link href="mailto: info@eh2.com" style={{ color: 'blue' }}>info@eh2.com</Link>, and we will help resolve your issue promptly.
            </p>
          </>
        )}
        &nbsp;
      </Typography>
    </div>
  );
}
