import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export type TitledBoxAlignment = 'left' | 'center' | 'right';

export interface LabeledBoxStylesProps {
  align: TitledBoxAlignment,
  fullHeight?: boolean;
}

export const useLabeledBoxStyles = makeStyles<Theme, LabeledBoxStylesProps>(theme => ({
  root: {
    position: 'relative',
    height: props => props.fullHeight ? '100%' : undefined,
  },
  titleOuter: {
    position: 'absolute',
    top: '-12px',
    display: 'flex',
    justifyContent: props => props.align,
    paddingLeft: props => props.align === 'left' ? theme.spacing(1.5) : undefined,
    paddingRight: props => props.align === 'right' ? theme.spacing(1.5) : undefined,
    width: '100%'
  },
  titleInner: {
    background: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  box: {
    border: 'solid 1px #ccc',
    borderRadius: theme.spacing(1.5),
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
    height: '100%'
  }
}));
