import { createTheme, Theme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export type { Theme };

export const borderRadius = 4;

export const theme = createTheme({
  palette: {
    background: {
      default: '#fafafa',
    },
    primary: { main: '#333' },
    secondary: red,
    success: {
      main: '#179541',
      light: '#92D050',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontSize: 14,
    allVariants: {
      lineHeight: 1.425,
    },
    subtitle1: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    h1: { fontSize: '2.5rem' },
    h2: { fontSize: '1.75rem', fontWeight: 500 },
    h3: { fontSize: '1.25rem' },
    h4: { fontSize: '1.05rem' },
    h5: { fontSize: '1.05rem' },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
      },
      styleOverrides: {
        marginDense: {
          marginBottom: 2,
          marginTop: 2,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius,
          '& $notchedOutline': {
            padding: 0,
          },
        },
        adornedStart: {
          paddingLeft: 12,
        },
        adornedEnd: {
          paddingRight: 6,
        },
        multiline: { borderRadius },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&$shrink': {
            // transform: 'translate(4px, -6px) scale(0.75)',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          borderRadius,
          fontSize: '.75rem',
          fontWeight: 'normal',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        transitionDuration: 0,
      },
      styleOverrides: {
        paper: {
          borderRadius: borderRadius * 2,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          transition: undefined,
        },
        deleteIcon: {
          width: undefined,
          height: undefined,
        },
        clickable: {
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          border: 'solid 1px #333',
        },
      },
    },
    MuiRadio: {
      defaultProps: { color: 'primary' },
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No ripple on the whole application
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: { square: true },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});
