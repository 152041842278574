import { makeStyles } from '@mui/styles';
import type { Theme } from '@mui/material/styles';

export const useEmptyStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
    },
    icon: {
      '&.MuiSvgIcon-root': { // extra specificity weight
        marginBottom: theme.spacing(5),
      }
    },
    text: {
      lineHeight: 1,
    },
  };
});
